<template>
  <validation-observer
    ref="opportunitiesForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form
      autocomplete="off"
      @submit.prevent="handleSubmit(updateOpportunity)"
    >
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="true"
        :can-cancel="false"
      />

      <!-- page header component -->
      <page-header
        :name="opportunity.name"
        icon="bullseye"
      >
        <template #actions>
          <div class="level-item">
            <b-field>
              <b-button
                :disabled="invalid"
                native-type="submit"
                type="is-success"
                icon-left="content-save"
              >
                update opportunity
              </b-button>
            </b-field>
          </div>
        </template>
      </page-header>

      <!-- opportunities form component -->
      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-three-quarters">
              <!-- {{ opportunity }} -->
              <opportunities-form
                :opportunity-stages="opportunityStages"
                :opportunity-types="opportunityTypes"
                :temperatures="temperatures"
                :probabilities="probabilities"
              />
            </div>
          </div>

          <b-field>
            <b-button
              :disabled="invalid"
              native-type="submit"
              type="is-success"
              icon-left="content-save"
              value="update opportunity"
            >
              update opportunity
            </b-button>
          </b-field>
        </div>
      </section>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { mapGetters } from 'vuex';

import { OpportunitiesForm } from '@/components/Opportunities';
import { PageHeader } from '@/components/Shared';

import OpportunitiesMixin from '@/mixins/OpportunitiesMixin';

export default {
  name: 'EditOpportunity',

  components: {
    ValidationObserver,
    OpportunitiesForm,
    PageHeader,
  },

  mixins: [OpportunitiesMixin],

  computed: mapGetters({
    opportunityStages: 'Lookups/getOpportunityStages',
    opportunityTypes: 'Lookups/getOpportunityTypes',
    temperatures: 'Lookups/getTemperatures',
    probabilities: 'Lookups/getProbabilities',
    opportunity: 'Opportunities/getOpportunity',
  }),

  methods: {
    async updateOpportunity() {
      this.isLoading = true;
      const payload = { ...this.opportunity };
      try {
        this.opportunityStages.forEach((stage) => {
          if (stage.orderSequence === parseInt(payload.stageId, 10)) {
            payload.stageId = stage.id;
          }
        });

        payload.typeId = null;
        payload.opportunityTypes = this.opportunity.typeId ? this.opportunity.typeId.map((i) => ({
          lookupId: i,
        })) : null;

        /*
         * Fetch tags from payload
        */
        if (payload.tags) {
          /*
           * If a tag is an object extract its tag property value, otherwise
           * extract its string value.
           *
           * A tag is an object if it already exists in the database
           */
          const tags = payload.tags.map((t) => t.tag || t);
          const uniqueTags = [...new Set(tags)]; // remove duplicate tags
          payload.tags = uniqueTags;
        } else {
          payload.tags = [];
        }

        if (payload.owner.opportunityOwnerId) {
          await this.$store.dispatch('Opportunities/updateOpportunityOwner', {
            opportunityId: this.$route.params.opportunityId,
            id: payload.owner.opportunityOwnerId,
            ownerId: payload.owner.id,
            percentage: payload.owner.percentage,
          });
        }

        if (!payload.coOwner.opportunityOwnerId && payload.coOwner.id) {
          await this.$store.dispatch('Opportunities/createOpportunityOwner', {
            opportunityId: this.$route.params.opportunityId,
            owner: payload.coOwner,
          });
        }

        if (payload.coOwner.opportunityOwnerId) {
          await this.$store.dispatch('Opportunities/updateOpportunityOwner', {
            opportunityId: this.$route.params.opportunityId,
            id: payload.coOwner.opportunityOwnerId,
            ownerId: payload.coOwner.id,
            percentage: payload.coOwner.percentage,
          });
        } else if (this.$route.name === 'AddOpportunity' && payload.coOwner.id) {
          await this.$store.dispatch('Opportunities/createOpportunityOwner', {
            opportunityId: this.$route.params.opportunityId,
            owner: payload.coOwner,
          });
        }

        payload.projectFolderId = [];

        await this.$store.dispatch('Opportunities/updateOpportunity', {
          opportunityId: this.$route.params.opportunityId,
          opportunity: payload,
        });
        // await this.$router.push({
        //   path: `/opportunities/${this.$route.params.opportunityId}/view`,
        //   query: this.opportunity,
        // });
        await this.$router.push({
          path: '/opportunities',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
