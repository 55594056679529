import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    isLoading: true,
  }),

  async created() {
    try {
      await this.fetchOpportunityStages();
      await this.fetchOpportunityTypes();
      await this.fetchTemperatures();
      await this.fetchAccountTypes();
      await this.fetchProbabilities();
      await this.fetchLeadStatuses();
      await this.fetchLeadSources();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  mounted() {
    try {
      if (this.$route.name === 'AddOpportunity' && this.$route.query.contactId) {
        this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT_ID',
          this.$route.query.contactId,
        );
        this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT',
          this.$route.query.contact,
        );
      }

      if (this.$route.name === 'AddOpportunity' && this.$route.query.accountId) {
        this.$store.commit('Opportunities/SET_OPPORTUNITY_ACCOUNT_ID', this.$route.query.accountId);
        this.$store.commit('Opportunities/SET_OPPORTUNITY_ACCOUNT', this.$route.query.account);
      }
    } catch (error) {
      console.error(error);
    }
  },

  computed: mapGetters({
    opportunityStages: 'Lookups/getOpportunityStages',
    opportunityTypes: 'Lookups/getOpportunityTypes',
    temperatures: 'Lookups/getTemperatures',
    probabilities: 'Lookups/getProbabilities',
    opportunity: 'Opportunities/getOpportunity',
  }),

  methods: {
    /**
     *
     * map Vuex actions
     *
     */
    ...mapActions({
      fetchAccountTypes: 'Lookups/fetchAccountTypes',
      fetchOpportunityStages: 'Lookups/fetchOpportunityStages',
      fetchOpportunityTypes: 'Lookups/fetchOpportunityTypes',
      fetchTemperatures: 'Lookups/fetchTemperatures',
      fetchProbabilities: 'Lookups/fetchProbabilities',
      fetchLeadStatuses: 'Lookups/fetchLeadStatuses',
      fetchLeadSources: 'Lookups/fetchLeadSources',
      fetchOpportunity: 'Opportunities/fetchOpportunity',
    }),
  },

};
