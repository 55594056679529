<template>
  <div>
    <!-- page header component -->
    <page-header
      name="opportunities"
      icon="bullseye"
    >
      <template #actions>
        <div class="level-item">
          <search-bar />
        </div>

        <div class="level-item">
          <b-dropdown aria-role="list">
            <b-button
              slot="trigger"
              type="is-info"
              icon-left="plus"
              icon-right="chevron-down"
            >
              create
            </b-button>
            <template v-if="hasPermissions('create_accounts')">
              <b-dropdown-item
                has-link
                aria-role="listitem"
              >
                <router-link to="/accounts/add">
                  Account
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_contacts')">
              <b-dropdown-item
                has-link
                aria-role="listitem"
              >
                <router-link to="/contacts/add">
                  Contact
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                has-link
                aria-role="listitem"
              >
                <router-link to="/leads/add">
                  Lead
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                has-link
                aria-role="listitem"
              >
                <router-link to="/opportunities/add">
                  Opportunity
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <b-dropdown-item
              v-if="hasPermissions('create_activities')"
              has-link
              aria-role="menuitem"
            >
              <router-link to="/activities/add">
                Activity
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </page-header>

    <widget-collapse-drawer>
      <template #breadcrumb>
        <div class="container">
          <b-breadcrumb size="is-small">
            <b-breadcrumb-item
              tag="router-link"
              to="/profile"
            >
              Home
            </b-breadcrumb-item>
            <b-breadcrumb-item
              tag="router-link"
              to="/opportunities"
              active
            >
              Opportunities
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <section class="section has-background-light">
        <div class="container">
          <div class="columns">
            <div class="column is-one-third">
              <open-opportunities-by-salesperson @open-drilldown="openDrilldown" />
            </div>

            <div class="column is-one-third">
              <weighted-revenue-by-stage @open-drilldown="openDrilldown" />
            </div>

            <div class="column is-one-third">
              <opportunity-date-status @open-drilldown="openDrilldown" />
            </div>
          </div>
        </div>
      </section>
    </widget-collapse-drawer>

    <section class="section">
      <div class="container">
        <b-tabs v-model="activeTab">
          <b-tab-item label="overview">
            <opportunities-table />
          </b-tab-item>

          <b-tab-item
            :visible="showDrilldown"
            label="drilldown"
          >
            <opportunities-drilldown-table :is-drilldown-active="showDrilldown" />
          </b-tab-item>

          <!-- TODO: Enable on dev server -->
          <b-tab-item
            :visible="false"
            label="stage view"
          >
            <opportunities-stages />
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { PageHeader, WidgetCollapseDrawer } from '@/components/Shared';
import {
  OpportunitiesStages,
  OpportunitiesTable,
} from '@/components/Opportunities';
import { SearchBar } from '@/components/Search';
import {
  OpenOpportunitiesBySalesperson,
  OpportunityDateStatus,
  WeightedRevenueByStage,
} from '@/components/Graphs';
import { OpportunitiesDrilldownTable } from '@/components/DrilldownTables';

export default {
  name: 'ListOpportunities',

  components: {
    OpportunitiesStages,
    OpportunitiesTable,
    PageHeader,
    SearchBar,
    OpenOpportunitiesBySalesperson,
    OpportunityDateStatus,
    WeightedRevenueByStage,
    OpportunitiesDrilldownTable,
    WidgetCollapseDrawer,
  },

  data: () => ({
    activeTab: 0,
    showDrilldown: false,
  }),

  watch: {
    activeTab: {
      handler(value) {
        if (value === 0 || value === 2) {
          this.$router.replace('/opportunities');
          this.showDrilldown = false;
        }
      },
    },
  },

  mounted() {
    if (this.hasRole('ROLE_BUSINESS_ADMIN') || this.hasRole('ROLE_SALES_MANAGER')) {
      this.$store.dispatch('Users/fetchSubordinates');
      this.$store.dispatch('Graphs/fetchTeams');
      this.$store.dispatch('Graphs/fetchDivisions');
    }

    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      this.showDrilldown = true;
      this.activeTab = 1;
    }

    // if (this.$route.name === 'ListOpportunities') {
    //   this.$store.dispatch('Lookups/fetchTemperatures');
    //   this.$store.dispatch('Lookups/fetchProbabilities');
    //   this.$store.dispatch('Lookups/fetchDateColorConfig', {
    //     dateField: 'ESTBOOKINGDATE',
    //   });
    //   this.$store.dispatch('Lookups/fetchDateColorConfig', {
    //     dateField: 'BIDDUEDATE',
    //   });
    //   this.$store.dispatch('Lookups/fetchDateColorConfig', {
    //     dateField: 'ESTINSTALLDATE',
    //   });
    //   this.$store.dispatch('Lookups/fetchDateColorConfig', {
    //     dateField: 'ESTINVOICEDATE',
    //   });
    //   this.$store.dispatch('Opportunities/fetchOpportunityReasonStages');
    // }
  },

  methods: {
    openDrilldown(value) {
      this.showDrilldown = true;
      this.activeTab = 1;
      this.$router.replace({ query: value.filters });
    },
  },

};
</script>

<style scoped></style>
